import $ from 'jquery';

function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}

function createAcceptCookie(cookieBarMode) {
  const cookieBanner = document.getElementById('cookie-bar');
  document.cookie = 'cookiebar=hide; path=/; expires=' + setExpiryDate() + ';';
  document.cookie = 'cookie_tracking_status=true; path=/; expires=' + setExpiryDate() + ';';
  if (cookieBanner) {
    cookieBanner.classList.remove('show-cookie-bar');
  }
  const contentSlider = document.getElementsByClassName('content-slider');
  if (contentSlider) {
    $('.consentNotice').addClass('hidden');
  }
  if (cookieBarMode === 'opt-in' || cookieBarMode === 'blocker') {
    window.dataLayer.push({
      event: 'tracking_true'
    });
    placeFEcookie(1);
    checkTracking(cookieBarMode);
    showCookieButton();
  } else {
    hideCookieBar();
  }
  if (cookieBarMode === 'blocker') {
    hideBlockerModal();
  }
}

function hideCookieBar() {
  const cookieBanner = document.getElementById('cookie-bar');
  cookieBanner.classList.remove('show-cookie-bar');
}

function createDeclineCookie(cookieBarMode) {
  deleteAllCookies();
  document.cookie = 'cookiebar=hide; path=/; expires=' + setExpiryDate() + ';';
  document.cookie = 'cookie_tracking_status=false; path=/; expires=' + setExpiryDate() + ';';

  if (cookieBarMode === 'blocker') {
    hideBlockerModal();
  } else {
    const cookieBanner = document.getElementById('cookie-bar');
    cookieBanner.classList.remove('show-cookie-bar');
  }

  if (cookieBarMode === 'opt-in' || cookieBarMode === 'blocker') {
    placeFEcookie(0);
  }

  const contentSlider = document.getElementsByClassName('content-slider');
  if (contentSlider) {
    $('.consentNotice').removeClass('hidden');
  }
  checkTracking(cookieBarMode);
  showCookieButton();
}

function setExpiryDate() {
  var d = new Date();
  d.setFullYear(d.getFullYear() + 1);
  return d.toUTCString();
}

function showCookieButton() {
  document.getElementById('cookie-button').parentNode.classList.remove('hidden');
}

function checkConsentRadioButtons() {
  const trackingCookie = getCookie('cookie_tracking_status');
  let radioButtonDecline = document.getElementById('radiobutton-decline');
  let radioButtonAccept = document.getElementById('radiobutton-accept');
  if (trackingCookie === true || trackingCookie === 'true') {
    radioButtonAccept.checked = true;
  } else {
    radioButtonDecline.checked = true;
  }
}

function showCookieBar() {
  const controls = document.getElementById('consent-controls');
  const additionalControls = document.getElementById('consent-change');
  checkConsentRadioButtons();
  additionalControls.classList.remove('hidden');
  controls.classList.add('hidden');
  document.getElementById('cookie-bar').classList.add('show-cookie-bar');
}

function showBlockerModal() {
  $('#cookie-bar-blocker').show();
}

function hideBlockerModal() {
  $('#cookie-bar-blocker').hide();
}

function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  const cookiesToKeep = 'lang-modal, cookiebar, cookie_tracking_status';
  let domain = window.location.hostname;

  for (var i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf('=');
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    if (cookiesToKeep.indexOf(name) === -1) {
      document.cookie = name + '=;domain=' + domain + ';expires=Thu, 01 Jan 1970 00:00:00 GMT';
      document.cookie = name + '=;domain=.' + domain + ';expires=Thu, 01 Jan 1970 00:00:00 GMT';
      document.cookie = name + '=;domain=' + domain.replace('www.', '') + ';expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
}

function checkTracking(cookieBarMode) {
  const trackingCookie = getCookie('cookie_tracking_status');
  if (cookieBarMode !== 'blocker') {
    checkConsentRadioButtons();
  }
  window.dataLayer = window.dataLayer || [];
  if (trackingCookie === true || trackingCookie === 'true') {
    window.dataLayer.push({ cookie_tracking_status: 'true' });
  } else {
    window.dataLayer.push({ cookie_tracking_status: 'false' });
  }
}

function storeConsent(conURL) {
  let xhttp = new XMLHttpRequest();
  xhttp.open('GET', conURL, true);
  xhttp.send();
}

function placeFEcookie(value) {
  let xhttp = new XMLHttpRequest();
  const ajaxPageType = '/?type=869';
  const consentParam = '&consentid=';
  const consentValue = '&consentvalue=' + value;
  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      const conId = getCookie('consentID');
      const conURL = ajaxPageType + consentParam + conId + consentValue;
      storeConsent(conURL);
    }
  };
  xhttp.open('GET', ajaxPageType, true);
  xhttp.send();
}

export default function initCookieBar() {
  let isCookieSet = getCookie('cookiebar');
  let body = document.querySelector('body');
  const pageUid = body.dataset.uid;
  const domain = window.location.hostname;
  const domainPrefix = domain.slice(0, 4);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const graweParam = urlParams.get('grawe');
  let disabledUids = '';
  const footerButton = document.getElementById('cookie-button');
  const isDataReporterMode = footerButton.dataset.reporterMode === 'true';

  if (typeof disabledBar !== 'undefined') {
    // eslint-disable-next-line
    disabledUids = disabledBar.split(',');
  }

  if (domainPrefix === 'wwwt' && graweParam !== '1') {
    document.cookie = 'grawe=1; path=/; expires=' + setExpiryDate() + ';';
    document.cookie = 'cookie_tracking_status=false; path=/; expires=' + setExpiryDate() + ';';
    document.cookie = 'cookiebar=hide; path=/; expires=' + setExpiryDate() + ';';
  }

  if (disabledUids.includes(pageUid) === false) {
    if (isDataReporterMode) {
      // function dr_revokeChoice() provided by cookie plugin, to show cookie modal
      // eslint-disable-next-line no-undef
      footerButton.addEventListener('click', () => dr_revokeChoice());
    }

    const cookieBarPresent = document.getElementById('cookie-bar');

    if (cookieBarPresent) {
      // eslint-disable-next-line
      const cookieBarMode = cookieBarVariant;
      const acceptButton = document.getElementById('cookiebar-close');
      const declineButton = document.getElementById('cookiebar-decline');
      const hideButton = document.getElementById('cookiebar-hide');
      const radioAcceptButton = document.getElementById('radiobutton-accept');
      const radioDeclineButton = document.getElementById('radiobutton-decline');
      const footerButton = document.getElementById('cookie-button');
      const videoNotice = document.getElementsByClassName('showCookieBar');

      if (acceptButton) {
        acceptButton.addEventListener('click', () => createAcceptCookie(cookieBarMode));
      }

      if (cookieBarMode === 'opt-in') {
        footerButton.addEventListener('click', () => showCookieBar());
        declineButton.addEventListener('click', () => createDeclineCookie(cookieBarMode));
        hideButton.addEventListener('click', () => hideCookieBar());
        radioAcceptButton.addEventListener('click', () => createAcceptCookie(cookieBarMode));
        radioDeclineButton.addEventListener('click', () => createDeclineCookie(cookieBarMode));
        Array.from(videoNotice).forEach(function (element) {
          element.addEventListener('click', () => showCookieBar());
        });
      } else if (cookieBarMode === 'blocker') {
        footerButton.addEventListener('click', () => showBlockerModal());
        if (declineButton) {
          declineButton.addEventListener('click', () => createDeclineCookie(cookieBarMode));
        }
        Array.from(videoNotice).forEach(function (element) {
          element.addEventListener('click', () => showBlockerModal());
        });
      }

      if (isCookieSet === '') {
        if (cookieBarMode === 'info' || cookieBarMode === 'opt-in') {
          document.getElementById('cookie-bar').classList.add('show-cookie-bar');
        } else if (cookieBarMode === 'blocker') {
          showBlockerModal();
        }
      } else {
        if (cookieBarMode === 'opt-in' || cookieBarMode === 'blocker') {
          showCookieButton();
        }
      }
    }
  }
}
