export const getDatepickerLanguage = () => {
  const lang = document.documentElement.getAttribute('lang');

  switch (lang) {
    case 'de':
      return 'de-DE';
    case 'sl':
      return 'sl-SI';
    case 'hu':
      return 'hu-HU';
    case 'uk':
      return 'uk-UA';
    case 'ro':
      return 'ro-RO';
    case 'it':
      return 'it-IT';
    default:
      return '';
  }
};
