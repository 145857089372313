import $ from 'jquery';
import { open3rdLevelMenu } from './header';

export default function initA11yNavigation() {
  // open up 2nd level menu
  $('.level-1-a')
    .siblings('button')
    .on('keydown', function (e) {
      if (e.code !== 'Space' && e.code !== 'Enter') return;
      e.preventDefault();

      // reset level-1 and level-2 items
      resetAriaExpandedLevel1();
      resetAriaExpandedLevel2();

      const closestListItem = $(this).closest('li');
      const isMobile = $('.header__navigation-toggle').hasClass('open');
      if (closestListItem.hasClass('has-submenu')) {
        closestListItem.toggleClass('nav-opened');
        const subMenuButton = $(this);
        const isExpanded = closestListItem.hasClass('sub-opened');
        setAriaExpanded(subMenuButton, !isExpanded);

        if (isMobile) return;

        const level2 = closestListItem.find('.level-2');

        // close other level2 menus if open on desktop
        if (closestListItem.hasClass('nav-opened')) {
          updateTabIndices(level2, true);
          $('.nav-opened button[aria-expanded="true"]')
            .not(closestListItem.find('button'))
            .attr('aria-expanded', 'false');
          $('.nav-opened').not(closestListItem).removeClass('nav-opened');
          $('.sub-opened').not(closestListItem).removeClass('sub-opened');
          $('.dropdown--2-levels').not(closestListItem.find('.dropdown--2-levels')).css('display', 'none');
          closestListItem.find('.dropdown--2-levels').css('display', 'block');
        } else {
          closestListItem.find('.dropdown--2-levels').css('display', 'none');
        }
      }

      // if 3 levels, set aria-expanded on active level-2 item
      if (closestListItem.find('.dropdown--3-levels').length > 0) {
        setAriaExpanded(closestListItem.find('.dropdown--3-levels').find('.active').next(), true);
      }
    });

  // open up 3rd level menu
  $('.level-2 li button').on('keydown', function (e) {
    if (e.code !== 'Space' && e.code !== 'Enter') return;
    e.preventDefault();
    const subSubMenuButton = $(this);
    const activeLevel2Item = subSubMenuButton.prev('a');
    // reset level-2 items
    resetAriaExpandedLevel2();

    if (activeLevel2Item.hasClass('has-submenu')) {
      const isExpanded = activeLevel2Item.hasClass('active');
      setAriaExpanded(subSubMenuButton, isExpanded);

      const activeLevel2Id = activeLevel2Item.attr('id');
      const currentFirstMenuItem = $(`#${activeLevel2Id}-content li:first-child a`);
      const currentLastMenuItem = $(`#${activeLevel2Id}-content li:last-child a`);
      const generalLevel3LastMenuItem = $('.level-3 .level-3-container:last-child li:last-child a');
      const isMobile = $('.header__navigation-toggle').hasClass('open');

      open3rdLevelMenu(activeLevel2Item, false);
      if (!isMobile) updateTabIndices(`#${activeLevel2Id}-content`, true);

      let prevButton = e.code;
      subSubMenuButton.on('keydown', function (e) {
        // if level 3 is opened, we want to continue to first menu item
        if (!isMobile && e.code === 'Tab' && (prevButton === 'Space' || prevButton === 'Enter')) {
          e.preventDefault();
          currentFirstMenuItem.trigger('focus');
        }
        prevButton = e.code;
      });

      currentLastMenuItem.on('keydown', function (e) {
        // after last item of current level3 menu is tabbed, jump back to next level2 item
        if (!currentLastMenuItem.is(generalLevel3LastMenuItem)) {
          if (e.code === 'Tab' && !e.shiftKey) {
            e.preventDefault();
            const nextLevel2Item = activeLevel2Item.closest('li').next().find('a');
            nextLevel2Item.trigger('focus');
          }
        }
      });
    }
  });

  // level-1-a add aria-expanded on mouseenter
  $('.header__navbar__item').on('mouseenter', function () {
    if (isMobile()) return;
    // reset level-1 items
    resetAriaExpandedLevel1();
    // reset level-2 items
    resetAriaExpandedLevel2();

    if (!$(this).hasClass('has-submenu')) return;

    const dropdown = $(this).find('.dropdown');
    if (dropdown.css('display') === 'block') {
      const button = $(this).find('button').first();
      setAriaExpanded(button, true);
      // if 3 levels, set aria-expanded on active level-2 item
      if ($(this).find('.dropdown--3-levels').length > 0) {
        setAriaExpanded($(this).find('.dropdown--3-levels').find('.active').next(), true);
      }
    }
  });

  // level-1-a reset aria-expanded on mouseleave
  $('.header__navbar__item').on('mouseleave', function () {
    if (isMobile() || !$(this).hasClass('has-submenu')) return;

    const dropdown = $(this).find('.dropdown');

    if (dropdown.css('display') !== 'block') {
      const button = $(this).find('button').first();
      setAriaExpanded(button, false);
      // reset level-2 items
      resetAriaExpandedLevel2();
    }
  });

  // level-2 add aria-expanded on mouseenter
  $('.level-2 li').on('mouseenter', function () {
    if (isMobile()) return;
    // reset level-2 items
    resetAriaExpandedLevel2();

    const button = $(this).find('button').first();
    setAriaExpanded(button, true);
  });

  // Update sub menu SBS, career, blog
  const sbsMenuToggle =
    '.header__sbs__collapsed-menu-toggle, .header__career__collapsed-menu-toggle, .header__blog__collapsed-menu-toggle';
  const subMenuMore = '#subheader-more-menu a';
  $(sbsMenuToggle).on('mouseenter', function () {
    setAriaExpanded($(this).find('button'), true);
  });
  $(sbsMenuToggle).on('mouseleave', function () {
    setAriaExpanded($(this).find('button'), false);
  });
  $(sbsMenuToggle)
    .find('button')
    .on('focus', function () {
      const isExpanded = $(this).closest('div').find('ul').css('display') === 'block';
      setAriaExpanded($(this), isExpanded);
    });
  $(sbsMenuToggle)
    .find('button')
    .on('focusout', function () {
      const isExpanded = $(this).closest('div').find('ul').css('display') === 'block';
      setAriaExpanded($(this), isExpanded);
    });
  $(subMenuMore).on('focus', function () {
    setAriaExpanded($(this).closest('div').find('button'), true);
  });
  $(subMenuMore).on('focusout', function () {
    setAriaExpanded($(this).closest('div').find('button'), false);
  });
}

// Add loop within mobile menu
$('nav').on('keydown', function (e) {
  const isMobile = $('.header__navigation-toggle').hasClass('open');
  if (e.key === 'Tab' && !e.shiftKey && isMobile) {
    const lastTabableMobileMenuItem = $('nav').find('a').last();
    const navToggle = $('.header__navigation-toggle');
    if ($(document.activeElement).is(lastTabableMobileMenuItem)) {
      e.preventDefault();
      navToggle.trigger('focus');
    }
  }
});

export const updateTabIndices = (container, shouldAddTabIndex = true) => {
  const tabableElements = $(container).find('a');

  if (shouldAddTabIndex) {
    tabableElements.attr('tabindex', '0');
  } else {
    tabableElements.attr('tabindex', '-1');
  }
};

export const setAriaExpanded = (element, isExpanded) => {
  element.attr('aria-expanded', isExpanded);
};

const resetAriaExpandedLevel1 = () => {
  setAriaExpanded($('.level-1').find('.has-submenu').find('button'), false);
};

const resetAriaExpandedLevel2 = () => {
  setAriaExpanded($('.level-2').find('.has-submenu').siblings('button'), false);
};

const isMobile = () => {
  let width = $(window).width();
  if (width < window.breakpoints['md']) {
    return true;
  }

  return false;
};
