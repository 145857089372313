import $ from 'jquery';
import { setAriaExpanded } from './header_a11y';

const quicklinks = $('.quicklinks')[0];
const bannerHorizontal = '.quicklinks-banner-horizontal';
const bannerBadges = $('.quicklinks-banner-badges');
const bannerBadgesItems = '.quicklinks-banner-badges .quicklinks-banner-badges__item > a';
const sliderItem = '.container-feature';
const menuWrapper = '.quicklinks-button-wrapper';
const quicklinksButton = $(menuWrapper).parent();
const button = `${menuWrapper} button`;
const quicklinksMenuList = $('.quicklinks__menu__list');
const scrollHeight = $(bannerHorizontal).is(':visible')
  ? parseInt($(sliderItem).height()) + parseInt($(bannerHorizontal).height())
  : 0;
const content = $('#content')[0];
const items = $(bannerBadgesItems).toArray();
const header = $('.header')[0];
let stickyPosition;

export default function initQuicklinksBanner() {
  var timeout;
  var initialized = false; // initially open menu
  const openMenu = () => {
    $(quicklinksButton).addClass('open');
    setAriaExpanded($(button), true);
  };
  const closeMenu = () => {
    $(quicklinksButton).removeClass('open');
    setAriaExpanded($(button), false);
  };
  const hasQuicklinksBadges = bannerBadges && bannerBadges.length && quicklinks;

  if (hasQuicklinksBadges) {
    setSticky();
    $(window).resize(() => setSticky());
  }

  $(window).scroll(() => update());
  update();

  // only enable for hoverable devices, otherwise it will be handled by click & tab
  const isHoverable = window.matchMedia('(hover: hover)').matches;
  if (isHoverable) {
    $(button).on('mouseenter', () => {
      // open menu on mouse enter
      clearTimeout(timeout);
      if (initialized) {
        $(button).off('click');
        openMenu();
      } else {
        initialized = true;
      }
    });
  }

  // for clicking & tabbing
  $(button).on('click', function () {
    const isExpanded = $(this).closest('.quicklinks-button').hasClass('open');
    if (isExpanded) closeMenu();
    else openMenu();
  });

  // menu should close for every device on mouse leave
  $(button).on('mouseleave', closeMenu);

  // handle menu list hover
  $(quicklinksMenuList).on('mouseenter', openMenu);
  $(quicklinksMenuList).on('mouseleave', closeMenu);

  function setSticky() {
    if (!$(quicklinks).hasClass('sticky')) {
      let prevSibling = $(quicklinks).prev();
      stickyPosition = prevSibling[0].offsetTop + prevSibling.outerHeight();
    }
  }

  function update() {
    if ($(window).scrollTop() >= scrollHeight) {
      // show quickmenu button
      $(quicklinksButton).addClass('active');

      if (!initialized) {
        // initially open menu (for 4 seconds)
        openMenu();
        timeout = setTimeout(() => {
          closeMenu();
          initialized = true;
        }, 4000);
      }
    } else {
      // hide quickmenu button on the top of the page
      clearTimeout(timeout);
      $(quicklinksButton).removeClass('active open');
    }

    if (hasQuicklinksBadges) {
      setSticky();

      if (window.pageYOffset > +stickyPosition) {
        quicklinks.classList.add('sticky');
        content.classList.add('sticky-padding');

        const firstInView = items.find(link => $(link.hash).parent()().isInViewport());
        if (firstInView) {
          items.forEach(el => el.classList.add('grey'));
          firstInView.classList.remove('grey');
        } else {
          items.forEach(el => el.classList.remove('grey'));
        }
      } else {
        quicklinks.classList.remove('sticky');
        content.classList.remove('sticky-padding');
        items.forEach(el => el.classList.remove('grey'));
      }
    }
  }

  // check if quicklinks menu is higher than window height
  const contentHeight = window.innerHeight - (header ? header.offsetHeight : 0);
  const quicklinksButtonHeight = $(menuWrapper).outerHeight(true);
  const quicklinksButtonBottom = $(menuWrapper).css('bottom');
  const quicklinksHeight =
    $('footer .quicklinks__menu__list').outerHeight(true) + quicklinksButtonHeight + parseFloat(quicklinksButtonBottom);

  if (quicklinksHeight > contentHeight) {
    const right = $(menuWrapper).css('right');
    const width = $('.container-footer').innerWidth() - 2 * parseFloat(right);

    $('.quicklinks__menu__list').css('width', width);
    $(menuWrapper).addClass('small-height');
  }
}

$.fn.isInViewport = function () {
  if ($(this).offset()) {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();

    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  } else {
    return false;
  }
};
