import $ from 'jquery';

export default function initProductTeaserSlider() {
  $('.product-teaser-slider').each((num, el) => {
    el = $(el);

    const defaultSlides = 3;

    const slickSlider = el.slick();
    const slideCount = slickSlider.slick('getSlick').slideCount;
    const slidesToShow = slideCount < defaultSlides ? slideCount : defaultSlides;

    el.slick('unslick');

    el.slick({
      speed: 500,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow,
      arrows: true,
      pauseOnHover: true,
      prevArrow:
        '<button type="button" class="product-teaser-slider__navigation product-teaser-slider__navigation__prev"></button>',
      nextArrow:
        '<button type="button" class="product-teaser-slider__navigation product-teaser-slider__navigation__next"></button>',
      responsive: [
        {
          breakpoint: 1430,
          settings: {
            slidesToShow: 2.5
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  });

  // Slider which only exists on mobile, otherwise it's a simple grid.
  // We need to clone items first for a potential slider destruction
  // and replace the slider with the original grid.
  // can only happen on window resize
  const clonedItems = [];
  $('.scroll-x').each((_, el) => {
    const clone = $(el).clone(true);
    clonedItems.push(clone);
  });

  function handleMobileSlider() {
    $('.scroll-x').each((num, el) => {
      el = $(el);

      const windowWidth = $(window).width();

      // Initialize slick slider only for mobile screens (up to 768px)
      if (windowWidth <= 768) {
        if (!el.hasClass('slick-initialized')) {
          el.addClass('product-teaser-slider');
          el.slick();

          el.slick('unslick');

          el.slick({
            speed: 500,
            infinite: true,
            slidesToScroll: 1,
            arrows: true,
            pauseOnHover: true,
            prevArrow:
              '<button type="button" class="product-teaser-slider__navigation product-teaser-slider__navigation__prev"></button>',
            nextArrow:
              '<button type="button" class="product-teaser-slider__navigation product-teaser-slider__navigation__next"></button>',
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          });
        }
      } else {
        if (el.hasClass('slick-initialized')) {
          el.removeClass('product-teaser-slider');
          el.slick('unslick');
          el.replaceWith(clonedItems[num]);
        }
      }
    });
  }

  $(window).on('resize', handleMobileSlider);
  handleMobileSlider();
}
