import $ from 'jquery';
import initA11yNavigation, { updateTabIndices, setAriaExpanded } from './header_a11y';

const buffer = 50;

export default function initNavigation() {
  const body = 'body';
  const navToggle = '.header__navigation-toggle';
  const subNavToggle =
    '.header__sbs__navigation-toggle, .header__career__navigation-toggle, .header__blog__navigation-toggle';
  const header = '.header';
  const dropdownToggles = '.has-submenu .level-1-a+button';
  const scrollOffset = 75;
  let scrollDistance = 3;

  // Check if we are not in mobile view
  if ($(navToggle).css('display') === 'none') {
    renderMoreMenu();
  }

  // Sticky Navigation
  if ($(body).hasClass('grawe-sbs') || $(body).hasClass('grawe-karriere')) {
    scrollDistance = 80;
  }

  if (window.location.hash) {
    setTimeout(function () {
      try {
        $('html, body').animate(
          {
            scrollTop: $(window.location.hash).offset().top - scrollOffset + 'px'
          },
          500,
          'swing'
        );
      } catch (error) {
        console.warn('Not a valid selector to scroll to.');
      }
    }, 500);
  }

  $('a').on('click', function (event) {
    const anchorId = this.hash;
    if (anchorId !== '') {
      const anchorElement = document.getElementById(anchorId);

      if (anchorElement) {
        event.preventDefault();
        const hash = this.hash;
        $('html, body').animate(
          {
            scrollTop: $(hash).offset().top - scrollOffset + 'px'
          },
          500,
          'swing'
        );
      }
    }
  });
  $(window).scroll(function () {
    if ($(window).scrollTop() >= scrollDistance) {
      $(body).addClass('header--sticky');
      if ($('header').hasClass('header-extended')) $(body).addClass('header-extended--sticky');
    } else {
      $(body).removeClass('header--sticky');
      if ($('header').hasClass('header-extended')) $(body).removeClass('header-extended--sticky');
    }
  });

  // Language switcher
  $('.language__switcher__trigger').on('click', function () {
    $(this).toggleClass('menu--active');
    $(this).next().toggleClass('language__switcher__menu--open');
    $('.icon-nav-arrow-language-switcher').toggleClass('icon-nav-arrow-language-switcher--up');
    const isExpanded = $(this).hasClass('menu--active');
    setAriaExpanded($(this), isExpanded);
  });

  // More menu
  $('.more__menu__trigger').on('click', function () {
    $(this).toggleClass('menu--active');
    $(this).next('.more__menu').toggleClass('more__menu--open');
    const isExpanded = $(this).hasClass('menu--active');
    setAriaExpanded($(this), isExpanded);
  });

  // Desktop navigation
  // --------------------------------------------

  // activate the first 2nd level element
  // and the corresponding 3rd level dropdown on desktop
  openDefaultOnDesktop();
  $(window).resize(() => openDefaultOnDesktop());
  function openDefaultOnDesktop() {
    if ($(window).width() >= window.breakpoints['md']) {
      $('.header__navbar__item--main-menu.has-submenu').each(function () {
        // find the first level 2 item with a submenu
        const selectedElement = $(this).find('.active');
        const activeElement = $(this).find('.has-submenu').first();
        if (selectedElement.length == 1) {
          open3rdLevelMenu(selectedElement);
        } else {
          open3rdLevelMenu(activeElement);
        }
      });
    }
  }

  // Logic for a11y navigation
  initA11yNavigation();

  $('.dropdown').on('mouseleave', function () {
    $('.level-1-a').parent().parent().removeClass('nav-opened');
  });

  $('.header__navbar__item').on('click touchend', function (e) {
    let type = e.type;
    let width = $(window).width();
    let classList = e.target.classList;

    if (width >= window.breakpoints['md'] && type === 'touchend') {
      if (classList.contains('level-1-a')) {
        if (!$(this).hasClass('has-submenu')) {
          return;
        } else if ($(this).hasClass('hover')) {
          $(this).toggleClass('hover');
          e.preventDefault();
          e.stopPropagation();
          return;
        } else {
          $('header .hover').removeClass('hover');
        }

        $(this).toggleClass('hover');
        e.preventDefault();
        e.stopPropagation();

        $(window).one('click', function () {
          $('header .hover').removeClass('hover');
        });
      } else if (classList.contains('has-submenu')) {
        //get the active id from the level 2 element

        e.preventDefault();

        let activeElement = $(e.target);
        open3rdLevelMenu(activeElement);
      }
    }
  });

  // Open 3rd level dropdown on hover for desktop
  $('.level-2 .has-submenu').on('mouseenter', function (e) {
    let width = $(window).width();
    if (width < window.breakpoints['md']) {
      if (e.type === 'mouseenter') {
        return;
      }
    }
    //get the active id from the level 2 element
    let activeElement = $(this);
    open3rdLevelMenu(activeElement);
  });

  // Open 3rd level dropdown on click for mobile
  let numClicks = 0;
  $('.level-2 .has-submenu + button').on('click', function (e) {
    e.preventDefault();
    numClicks++;
    const listItem = $(this).closest('li');
    if (numClicks === 1) {
      let activeElement = listItem.find('a.has-submenu');
      open3rdLevelMenu(activeElement);
      setTimeout(function () {
        numClicks = 0;
      }, 400);
    } else if (numClicks == 2) {
      // avoid double click behaviour of wrong icon
      listItem.find('a').removeClass('active');
      $('.mobileClone.active').remove();
      numClicks = 0;
    }
  });

  // Mobile navigation
  // --------------------------------------------

  // open mobile nav when the toggle is clicked
  $(subNavToggle).click(function () {
    $(header).toggleClass('subnav-opened');
    $(this).find('.arrow').toggleClass('active');
    const isExpanded = $(this).find('.arrow').hasClass('active');
    setAriaExpanded($(this), isExpanded);
  });

  //nav toggle = burger menu
  // always close level 2 and 3 on toggle, reset max-heights
  $(navToggle).on('click', function () {
    $('.mobileClone.active').css('max-height', '');
    $('.mobileClone.active').removeClass('active');
    $('.has-submenu.active').removeClass('active');
    $('.header__navbar__item.sub-opened .dropdown').css('max-height', '');
    $('.header__navbar__item.sub-opened').removeClass('sub-opened');
    setAriaExpanded($('button'), false);

    if ($(navToggle).hasClass('open')) {
      $(navToggle).removeClass('open');
      $(header).removeClass('opened');
      $('body').css('overflow', 'visible');
      // enable menu for tabbing
      updateTabIndices($('.level-3'), true);
      setAriaExpanded($(this), false);
    } else {
      $(navToggle).addClass('open');
      $(header).addClass('opened');
      $('body').css('overflow', 'hidden');
      // disable menu for tabbing
      updateTabIndices($('.level-3'), false);
      setAriaExpanded($(this), true);

      //open insurance menu per default
      let insuranceLink = $("a[href='/versicherungen/']");
      let insuranceListItem = insuranceLink.closest('li');
      insuranceListItem.addClass('sub-opened');
      let dropdownHeight = insuranceListItem.find('.dropdown').prop('scrollHeight');
      insuranceListItem.find('.dropdown').css('max-height', dropdownHeight);
      insuranceListItem.find('.dropdown').css('transition-duration', '0s');
      setAriaExpanded(insuranceListItem.find('button').first(), true);

      // check if menu should be opened
      $('.current').each(function () {
        const listItem = $(this).closest('li');
        if (listItem.hasClass('has-submenu')) {
          listItem.addClass('sub-opened');

          const dropdown = listItem.find('.dropdown');
          let dropdownHeight = dropdown.prop('scrollHeight');
          dropdown.css('max-height', dropdownHeight);
          dropdown.css('transition-duration', '0s');
          listItem.find('.icon-nav-arrow-menu').css('transition', 'none');
          listItem.find('button').attr('aria-expanded', true);
        }

        // Open 3rd level
        if ($(this).closest('a').hasClass('has-submenu')) {
          open3rdLevelMenu($(this).closest('a'), false);
        }
      });

      $('.current .has-submenu').each(function () {
        $(this).parent().addClass('sub-opened');
        open3rdLevelMenu($(this));
      });
    }
  });

  // Level 2 menu trigger
  $(dropdownToggles).on('click keydown', function (e) {
    if (e.type === 'keydown' && e.code !== 'Space' && e.code !== 'Enter') return;
    if ($(window).width() < window.breakpoints['md']) {
      e.preventDefault();
    }
    let navItem = $(this).closest('.header__navbar__item');

    // reset transition for open menus
    if (navItem.find('.dropdown').css('transition-duration') == '0s') {
      navItem.find('.dropdown').css('transition-duration', '0.3s');
    }
    if (navItem.find('.icon-nav-arrow-menu').css('transition') == 'none 0s ease') {
      if (e.type === 'keydown') return;
      navItem.find('.icon-nav-arrow-menu').css('transition', 'all 0.5s ease');
    }

    navItem.toggleClass('sub-opened');
    const isExpanded = navItem.hasClass('sub-opened');
    navItem.find('button').first().attr('aria-expanded', isExpanded);
    // get scroll height to make accordeon effect for opening the menu
    let dropdownHeight = navItem.children('.dropdown').prop('scrollHeight');
    navItem.children('.dropdown').css('max-height', dropdownHeight);
    updateTabIndices(navItem.find('.dropdown--2-levels'), true);

    if ($(window).width() < window.breakpoints['md']) {
      if (!navItem.hasClass('sub-opened')) {
        // remove focuse
        navItem.children('a').trigger('blur');
        navItem.children('.dropdown').css('max-height', 0);
      }
    }
  });

  // Window resize functions
  // remove opened class if window gets resized from mobile to desktop
  $(window).on('resize', function () {
    if ($(window).width() >= window.breakpoints['md'] && $(header).hasClass('opened')) {
      $(header).removeClass('opened');
      // remove max-heights from opened menus and cloned nodes
      $('.dropdown').css('max-height', '');
      $('.mobileClone').remove();
    }

    // remove open class from nav toggle if from desktop to mobile
    if ($(window).width() < window.breakpoints['md'] && $(navToggle).hasClass('open')) {
      $(navToggle).removeClass('open');
    }

    // only render more menu on desktop
    if ($(navToggle).css('display') === 'none') {
      renderMoreMenu(true);
    }
  });
}

export function open3rdLevelMenu(activeElement, transition = true) {
  // Set timeout, so chrome doesn"t click on the link in the level 3 div behind
  setTimeout(function () {
    // 3rd Level menu opening/closing
    // mobile menu
    if ($(window).width() < window.breakpoints['md']) {
      activeElement.toggleClass('active');

      // copy third level menu content when opening and set active
      if (activeElement.hasClass('active')) {
        // if 3rd level is opened up by default, we don't want to show a transition
        if (!transition) {
          activeElement.closest('li').find('level-3-container').css('transition-duration', '0s');
          activeElement.closest('li').find('.icon-nav-arrow-menu').css('transition', 'none');
        }
        const mobileCloneMenu = $('#page-' + activeElement.data('page-uid') + '-content')
          .clone()
          .appendTo(activeElement.closest('li'))
          .addClass('mobileClone');

        mobileCloneMenu.addClass('active');
        // set max heights for transitions
        let dropdownHeightParent = activeElement.closest('.dropdown').prop('scrollHeight');
        let dropdownHeight =
          activeElement.closest('li').find('.mobileClone').prop('scrollHeight') + dropdownHeightParent;
        activeElement.closest('.dropdown').css('max-height', dropdownHeight);
        mobileCloneMenu.css('max-height', dropdownHeight);
        // enable tabindices for level-3 menu
        updateTabIndices(mobileCloneMenu, true);

        //Reset transition
        if (transition) activeElement.closest('li').find('.icon-nav-arrow-menu').css('transition', 'all 0.5s ease');
        setAriaExpanded(activeElement.closest('li').find('button'), true);
      }
      // remove third level menu content when closing
      else {
        activeElement.trigger('blur');
        let dropdownHeightParent = activeElement.closest('.dropdown').prop('scrollHeight');
        activeElement.closest('.dropdown').css('max-height', dropdownHeightParent);
        setAriaExpanded(activeElement.closest('li').find('button'), false);
        // disable tabindices for level-3 menu
        const mobileCloneMenu = activeElement.closest('li').find('.mobileClone');
        updateTabIndices(mobileCloneMenu, false);
        mobileCloneMenu.css('max-height', 0);
        setTimeout(function () {
          mobileCloneMenu.remove();
        }, 300);
      }
    } else {
      // desktop menu
      // deactivate all level 2 anchors on this menu
      activeElement.closest('li').siblings().find('a').removeClass('active');

      // hide all level 3 divs on this menu
      activeElement.closest('.dropdown-container').find('.level-3-container').removeClass('active');

      // activate current anchor
      activeElement.addClass('active');
      setAriaExpanded(activeElement.closest('li').find('button'), true);

      // set the level 3 div with the active id visible
      $('#page-' + activeElement.data('page-uid') + '-content').addClass('active');
    }
  }, 1);
}

// More menu
function moveMenuItemToMoreMenu(menuItem, moreMenu, menuItemText, resize) {
  if (moreMenu.find(`:contains('${menuItemText}')`).length) {
    return;
  }
  const menuItemClone = menuItem.clone();
  if (menuItemClone.hasClass('has-submenu')) {
    menuItemClone.find('.dropdown, .icon-nav-arrow').remove();
  }
  const isActive = menuItemClone.find('a').hasClass('current');
  menuItemClone.removeClass().find('a').removeClass().end().css('display', 'block');

  if (isActive) menuItemClone.find('a').addClass('current');

  if (resize) {
    moreMenu.prepend(menuItemClone);
  } else {
    moreMenu.append(menuItemClone);
  }
  menuItem.css('display', 'none');
}

function moveMenuItemFromMoreMenu(menuItem, moreMenu, menuItemText, moreMenuSwitcher) {
  menuItem.css('display', 'inline-block');
  moreMenu.find(`:contains('${menuItemText}')`).closest('li').remove();

  if (moreMenu.children().length === 0) {
    moreMenuSwitcher.css('display', 'none');
  }
}

function calculateLevel1SecondaryWidth() {
  return (
    $('.more__menu__switcher').outerWidth(true) +
    $('.language__switcher').outerWidth(true) +
    $('.searchTrigger').outerWidth(true)
  );
}

function renderMoreMenu(resize = false) {
  const headerWidth = $('.header').outerWidth(true);
  const moreMenuSwitcher = $('.more__menu__switcher');
  const level1SecondaryWidth = calculateLevel1SecondaryWidth();
  const logoWidth = $('.header__logo--img').outerWidth(true);
  const level1CoreMenu = $('.level-1-core');
  const moreMenu = $('.more__menu');
  const maxMenuWidth = headerWidth - level1SecondaryWidth - logoWidth;

  if (level1CoreMenu.outerWidth(true) + buffer < maxMenuWidth && !resize) {
    return;
  }

  let menuWidthSum = buffer;
  level1CoreMenu.children().each(function () {
    const menuItem = $(this);
    const menuItemWidth = menuItem.outerWidth(true);
    const menuItemText = menuItem.find('.linktext').text();

    if (menuWidthSum + menuItemWidth > maxMenuWidth) {
      moveMenuItemToMoreMenu(menuItem, moreMenu, menuItemText, resize);
    } else if (moreMenu.find(`:contains('${menuItemText}')`).length) {
      moveMenuItemFromMoreMenu(menuItem, moreMenu, menuItemText, moreMenuSwitcher);
    }
    menuWidthSum += menuItemWidth;
  });

  if (moreMenu.children().length) {
    moreMenuSwitcher.css({
      display: 'inline-block'
    });
    const left = (moreMenu.outerWidth(true) - moreMenuSwitcher.outerWidth(true)) / 2;
    moreMenu.css({
      left: `${left * -1}px`
    });
  }
}
